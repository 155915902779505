




























































import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerInfoProperties } from "./CustomerInfoView.vue";
import { CustomerAddressDialogProperties } from "./CustomerAddressDialogView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import CommonUtil from "@/utilities/CommonUtil";

const CustomerAddressDialogView = () => import("./CustomerAddressDialogView.vue");

@Component({
    components: { CustomerAddressDialogView },
    data: () => ({
        selected: [],
        filter: {
            name: "",
        },
    }),
})
export default class CustomerAddressView extends Vue {
    @Prop() private properties: CustomerInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerAddressDialog = new CustomerAddressDialogProperties();

    private get headers() {
        return [
            {
                text: this.$t("text.name"),
                value: "name",
                width: "20%",
                filter: (v) => {
                    if (!this.$data.filter.name) return true;
                    return v.includes(this.$data.filter.name);
                },
            },
            {
                text: this.$t("text.address"),
                value: "full",
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                align: "center",
                fixed: true,
                width: "120px",
                sortable: false,
            },
        ];
    }

    private create() {
        this.customerAddressDialog = new CustomerAddressDialogProperties();
        this.customerAddressDialog.customer = this.properties.customer;
        this.customerAddressDialog.isNew = true;
        this.customerAddressDialog.visible = true;
    }

    private modify(address: any) {
        this.customerAddressDialog = new CustomerAddressDialogProperties();
        this.customerAddressDialog.customer = this.properties.customer;
        this.customerAddressDialog.address = CommonUtil.clone(address);
        this.customerAddressDialog.isNew = false;
        this.customerAddressDialog.visible = true;
    }

    private remove() {
        //delete selected
        const addresses = this.properties.customer.addresses;
        const selected = this.$data.selected;
        this.properties.customer.addresses = addresses.filter((x) => {
            for (var i = 0; i < selected.length; i++) {
                if (selected[i].tempId === x.tempId) {
                    return false;
                }
            }
            return true;
        });
        this.$data.selected = [];
    }
}
