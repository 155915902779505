



































































import { Vue, Component, Prop } from "vue-property-decorator";
import AddressUtil from "@/utilities/AddressUtil";
import CountryService from "@/services/CountryService";
import StateService from "@/services/StateService";
import CityService from "@/services/CityService";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";

class CustomerAddressDialogProperties {
    customer: any = {};
    address: any = {};
    visible: boolean = false;
    isNew: boolean = false;
}

export { CustomerAddressDialogProperties };

@Component({
    data: () => ({
        loadingCountries: false,
        loadingStates: false,
        loadingCities: false,
        states: [],
        countries: [],
        cities: [],
        countryId: null,
        stateId: null,
        cityId: null,
    })    
})
export default class CustomerAddressDialogView extends Vue {
    @Prop({ default: new CustomerAddressDialogProperties() })
    private properties: CustomerAddressDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;

    private countryService = new CountryService();
    private stateService = new StateService();
    private cityService = new CityService();

    public get title() {
        const isNew = this.properties.isNew;
        const t = this.$t('title.customer-address');
        return t + ' - ' + (isNew ? this.$t('title.create') : this.$t('title.modify'))
    }

    public created() {
        const address = this.properties.address;
        this.$data.cityId = address.cityId;
        this.$data.stateId = address.city?.stateId;
        this.$data.countryId = address.city?.state?.countryId;

        this.loadCountries();
        this.loadStates();
        this.loadCities();
    }

    public async loadCountries() {
        this.$data.loadingCountries = true;

        try {
            const r = await this.countryService.get();
            const countries = r.data.countries;
            this.$data.countries = countries.sort((lhs, rhs) => rhs.id - lhs.id);
        } catch (e) {
            this.$data.countries = [];
        } finally {
            this.$data.loadingCountries = false;
        }
    }

    public async loadStates() {
        this.$data.loadingStates = true;

        try {
            if (this.$data.countryId) {
                const r = await this.stateService.get({ countryId: this.$data.countryId });
                const states = r.data.states;
                this.$data.states = states.sort((lhs, rhs) => rhs.id - lhs.id);
            } else {
                this.$data.states = [];
            }
        } catch (e) {
            this.$data.states = [];
        } finally {
            this.$data.loadingStates = false;
        }
    }

    public async loadCities() {
        this.$data.loadingCities = true;

        try {
            if (this.$data.stateId) {
                const r = await this.cityService.get({ stateId: this.$data.stateId });
                const cities = r.data.cities;
                this.$data.cities = cities.sort((lhs, rhs) => rhs.id - lhs.id);
            } else {
                this.$data.cities = [];
            }
        } catch (e) {
            this.$data.cities = [];
        } finally {
            this.$data.loadingCities = false;
        }
    }

    public countryChanged() {
        this.loadStates();
    }

    public stateChanged() {
        this.loadCities();
    }

    private close() {
        this.properties.visible = false;
    }

    private submit() {
        const countryId = this.$data.countryId;
        const stateId = this.$data.stateId;
        const cityId = this.$data.cityId;
        const countries: any[] = this.$data.countries;
        const states: any[] = this.$data.states;
        const cities: any[] = this.$data.cities;

        const country = countries.find(x => x.id === countryId);
        const state = states.find(x => x.id === stateId);
        const city = cities.find(x => x.id === cityId);

        if (state) state.country = country;
        if (city) city.state = state;

        const addresses = this.properties.customer.addresses;
        const address = this.properties.address;
        address.cityId = cityId;
        address.city = city;
        address.full = AddressUtil.build(address);

        if (this.properties.isNew) {
            while (true) {
                var tempId = Math.random();
                if (!addresses.find(x => x.tempId === tempId)) {
                    address.tempId = tempId;
                    break;
                }
            }

            addresses.push(address);
            this.properties.visible = false;
        } else {
            for (var i = 0; i < addresses.length; i++) {
                if (addresses[i].tempId === address.tempId) {
                    Vue.set(addresses, i, address);
                }
            }
            this.properties.visible = false;
        }
    }
}
